// Interface for all getters, mutations and actions

// Getters
export const GET_PRESENCE_REGISTRATIONS = 'presence/GET_PRESENCE_REGISTRATIONS';
export const GET_PRESENCE_CONFIGURATION = 'presence/GET_PRESENCE_CONFIGURATION';
export const GET_PRESENCE_REGISTRATION_DETAIL = 'presence/GET_PRESENCE_REGISTRATION_DETAIL';
export const GET_PRESENCE_LOCATIONS = 'presence/GET_PRESENCE_LOCATIONS';
export const GET_MAIN_GROUPS = 'presence/GET_MAIN_GROUPS';
export const GET_PICKUP_RESPONSIBLES = 'presence/GET_PICKUP_RESPONSIBLES';
export const GET_LAST_UPDATE_PRESENCE_CONFIG = 'presence/GET_LAST_UPDATE_PRESENCE_CONFIG';
export const GET_LAST_REFRESH = 'presence/GET_LAST_REFRESH';
export const GET_OPEN_HOURS_CHECKIN_DASHBOARD = 'presence/GET_OPEN_HOURS_CHECKIN_DASHBOARD';
export const GET_IS_PRESENCE_DEVICE_REGISTERED = 'presence/GET_IS_PRESENCE_DEVICE_REGISTERED';

// Mutations
export const MUTATE_PRESENCE_CONFIGURATION = 'presence/MUTATE_PRESENCE_CONFIGURATION';
export const MUTATE_PRESENCE_REGISTRATION_DETAIL = 'presence/MUTATE_PRESENCE_REGISTRATION_DETAIL';
export const MUTATE_UPDATE_STATUS = 'presence/MUTATE_UPDATE_STATUS';
export const MUTATE_UPDATE_LOCATION = 'presence/MUTATE_UPDATE_LOCATION';
export const MUTATE_REGISTER_DEVICE = 'presence/MUTATE_REGISTER_DEVICE';
export const MUTATE_LOAD_PRESENCE_LOCATIONS = 'presence/MUTATE_LOAD_PRESENCE_LOCATIONS';
export const MUTATE_MAIN_GROUPS = 'presence/MUTATE_MAIN_GROUPS';
export const MUTATE_PICKUP_RESPONSIBLES = 'presence/MUTATE_PICKUP_RESPONSIBLES';
export const MUTATE_LAST_REFRESH = 'presence/MUTATE_LAST_REFRESH';
export const MUTATE_PRESENCE_OVERVIEW = 'presence/MUTATE_PRESENCE_OVERVIEW';
export const MUTATE_SET_IS_PRESENCE_DEVICE_REGISTERED = 'presence/MUTATE_SET_IS_PRESENCE_DEVICE_REGISTERED';

// Actions
export const LOAD_PRESENCE_CONFIGURATION = 'presence_device/LOAD_PRESENCE_CONFIGURATION';
export const LOAD_PRESENCE_REGISTRATION_DETAIL = 'presence/LOAD_PRESENCE_REGISTRATION_DETAIL';
export const UPDATE_STATUS = 'presence/UPDATE_STATUS';
export const UPDATE_LOCATION = 'presence/UPDATE_LOCATION';
export const ADD_SLEEP_INTERVAL = 'presence/ADD_SLEEP_INTERVAL';
export const UPDATE_SLEEP_INTERVAL = 'presence/UPDATE_SLEEP_INTERVAL';
export const DELETE_SLEEP_INTERVAL = 'presence/DELETE_SLEEP_INTERVAL';
export const UPDATE_ENTRY_TIME = 'presence/UPDATE_ENTRY_TIME';
export const UPDATE_EXIT_TIME = 'presence/UPDATE_EXIT_TIME';
export const UPDATE_EXIT_WITH = 'presence/UPDATE_EXIT_WITH';
export const UPDATE_COMMENT = 'presence/UPDATE_COMMENT';
export const REGISTER_DEVICE = 'presence/REGISTER_DEVICE';
export const LOAD_PRESENCE_LOCATIONS = 'presence/LOAD_PRESENCE_LOCATIONS';
export const SAVE_PICKUP_NAME = 'presence/SAVE_PICKUP_NAME';
export const CHECK_IN_DASHBOARD_ADD_VACATION = 'presence/CHECK_IN_DASHBOARD_ADD_VACATION';
export const UPDATE_SELF_DECIDER = 'presence/UPDATE_SELF_DECIDER';
export const LOAD_MAIN_GROUPS = 'presence/LOAD_MAIN_GROUPS';
export const LOAD_PICKUP_RESPONSIBLES = 'presence/LOAD_PICKUP_RESPONSIBLES';
export const LOAD_PRESENCE_OVERVIEW = 'presence/LOAD_PRESENCE_OVERVIEW';
