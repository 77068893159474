<template>
  <div class="add-vacation-container">
    <b-row>
      <b-col cols="6">
        <el-date-picker
          id="vacation-start"
          v-model="vacationStart"
          :class="{ 'red-border': isInvalidDate || isMissingDate }"
          :picker-options="pickerOptions"
          :format="defaultDateFormat"
          :value-format="defaultDateValueFormat"
          :disabled="isDisabledStartDate || isFormDisabled"
          :placeholder="'PRESENCE_VACATION_FR0M' | fromTextKey"
          type="date"
          @change="
            isMissingDate = false;
            isInvalidDate = false;
          "
          @focus="datePickerModifier()"
        />
      </b-col>
      <b-col cols="6">
        <el-date-picker
          id="vacation-end"
          v-model="vacationEnd"
          :class="{ 'red-border': isInvalidDate || isMissingDate }"
          :picker-options="pickerOptions"
          :format="defaultDateFormat"
          :value-format="defaultDateValueFormat"
          :disabled="isFormDisabled"
          :placeholder="'PRESENCE_VACATION_TO' | fromTextKey"
          type="date"
          @change="
            isMissingDate = false;
            isInvalidDate = false;
          "
          @focus="datePickerModifier()"
        />
      </b-col>
      <b-col v-if="isInvalidDate || isMissingDate" class="mt-1" cols="12">
        <b-alert variant="danger" show>
          {{ 'PRESENCE_REGISTER_VACATION_WARNING' | fromTextKey }}
        </b-alert>
      </b-col>
      <b-col class="mt-3" cols="12">
        <b-textarea
          id="vacation-comment"
          v-model="vacationComment"
          :disabled="isFormDisabled"
          placeholder="Evt. bemærkning"
          maxlength="250"
          class="dark-bg"
        />
        <b-btn
          v-if="!isFormDisabled"
          :variant="isEmployeeDashboard ? 'primary' : 'white'"
          class="pull-right add-vacation-button mt-2"
          :disabled="isLoading"
          @click="onClickSaveVacation()"
        >
          {{ 'BUTTON_SAVE' | fromTextKey }}
        </b-btn>
        <b-btn
          v-if="!isFormDisabled"
          variant="link"
          class="pull-right cancel-vacation-button mt-2 mr-2"
          @click="cancelForm"
        >
          {{ 'CANCEL' | fromTextKey }}
        </b-btn>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { presenceStates } from '../../enums/presenceStates';
import { presenceModules } from '../../enums/presenceModules';
import { types } from '../../../src_presence/store/types/types';
import moment from 'moment-timezone';
import { eventTypeEnum } from '../../enums/eventTypeEnum';

export default {
  props: {
    childIds: { type: Array, default: () => [] },
    isDisabledStartDate: { type: Boolean, default: true },
    isFormDisabled: { type: Boolean, default: false },
    vacationFormData: { type: Object, default: null },
    isChildOnVacation: { type: Boolean, default: false },
  },
  data: function () {
    return {
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      isLoading: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 86400000 < moment().toDate().getTime();
        },
        firstDayOfWeek: 1,
      },
      vacationStart: moment().format('YYYY-MM-DD'),
      vacationEnd: null,
      vacationComment: null,
      isMissingDate: false,
      isInvalidDate: false,
    };
  },
  computed: {
    ...mapGetters({
      defaultDateFormat: types.GET_DEFAULT_DATE_FORMAT,
      defaultDateValueFormat: types.GET_DEFAULT_DATE_VALUE_FORMAT,
      presenceConfiguration: types.GET_PRESENCE_CONFIGURATION,
    }),
    isEmployeeDashboard() {
      return this.$route.path.indexOf('kommegaa/medarbejder') != -1;
    },
  },
  methods: {
    ...mapActions({
      addVacationInCheckInDashboard: types.CHECK_IN_DASHBOARD_ADD_VACATION,
      addVacationInEmployeeDashboard: types.ADD_VACATION_FOR_CHILDREN,
      updateSimpleEvent: types.UPDATE_SIMPLE_EVENT,
    }),
    cancelForm() {
      this.$emit('cancelVacationForm');
    },
    updateStatusAfterAddedVacation() {
      this.$emit('saveVacation');
      if (moment(this.vacationStart).isSame(moment(), 'day')) {
        this.$emit('updateChildStatus', presenceStates.REPORTED_ABSENT);
      }
    },
    addNewVacation() {
      const params = {
        childIds: this.childIds,
        intervals: [
          {
            startDate: this.vacationStart,
            endDate: this.vacationEnd,
          },
        ],
        comment: this.vacationComment,
      };
      const addVacation = this.isEmployeeDashboard
        ? this.addVacationInEmployeeDashboard
        : this.addVacationInCheckInDashboard;
      return addVacation(params);
    },
    updateVacation() {
      const params = {
        eventId: this.vacationFormData.id,
        startDateTime: this.vacationStart,
        endDateTime: this.vacationEnd,
        type: eventTypeEnum.PRESENCE_HOLIDAY,
        description: this.vacationComment,
        title: this.vacationFormData.title,
        allDay: true,
        private: false,
        responseRequired: false,
        addedToInstitutionCalendar: false,
        hideInOwnCalendar: false,
        isEditEvent: true,
      };
      return this.updateSimpleEvent(params);
    },
    validate() {
      this.isMissingDate = !this.vacationStart || !this.vacationEnd;
      this.isInvalidDate = false;
      if (this.vacationStart && this.vacationEnd) {
        const startTime = moment(this.vacationStart, 'YYYY-MM-DD', true);
        const endTime = moment(this.vacationEnd, 'YYYY-MM-DD', true);
        if (!startTime.isValid() || !endTime.isValid() || endTime.isBefore(startTime)) {
          this.isInvalidDate = true;
        }
      }
    },
    async onClickSaveVacation() {
      this.validate();
      if (!this.isInvalidDate && !this.isMissingDate) {
        this.isLoading = true;
        if (this.isChildOnVacation) {
          await this.updateVacation();
        } else {
          await this.addNewVacation();
        }
        this.updateStatusAfterAddedVacation();
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (this.vacationFormData) {
      if (this.isChildOnVacation) {
        this.vacationStart = moment(this.vacationFormData.startDateTime).utc().format('YYYY-MM-DD');
        this.vacationEnd = moment(this.vacationFormData.endDateTime).utc().format('YYYY-MM-DD');
        this.vacationComment = this.vacationFormData.description ? this.vacationFormData.description.html : null;
      } else {
        this.vacationStart = this.vacationFormData.startDateTime;
      }
    }
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/core/variables';
@import '../../assets/scss/core/breakpoints';
.add-vacation-container {
  flex: 1;
  @include breakpoint-lg-down() {
    flex: unset;
    width: 100%;
  }
  .el-date-editor {
    width: 100%;
  }
  .cancel-vacation-button {
    color: $color-white;
    padding: 14px 44px;
    .theme-employee & {
      color: $color-grey;
    }
  }
  input.form-control {
    width: 100%;
    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      font-size: 18px;
    }
    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      font-size: 18px;
    }
    &::-ms-input-placeholder {
      /* Microsoft Edge */
      font-size: 18px;
    }
  }
  @include breakpoint-lg-down() {
    margin-top: 20px;
  }
}
</style>
