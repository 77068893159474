















































































import Timepicker from '../Timepicker.vue';
import { comeGoUtil } from '../../utils/comeGoUtil.js';
import { timepickerSettings } from '../../enums/timepickerSettings.js';
import { presenceStates } from '../../enums/presenceStates.js';
import { presenceModules } from '../../enums/presenceModules.js';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import { now } from '../../utils/dateUtil.js';
import { PropType } from 'vue';
import { SleepIntervalModel } from '../../models/sleepIntervalModel';
import AulaButton from '../AulaButton.vue';

export default {
  name: 'SleepTimePicker',
  components: { AulaButton, Icon, Timepicker },
  props: {
    sleepTime: { type: Object as PropType<SleepIntervalModel>, required: true },
    disabled: { type: Boolean, default: false },
  },
  emits: ['saveSleepTime', 'cancelAddingSleepTime', 'removeSleepInterval', 'onTimePickerChange'],
  data: function () {
    return {
      comeGoUtil: comeGoUtil,
      timepickerSettings: timepickerSettings,
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      startTime: this.sleepTime.startTime,
      endTime: this.sleepTime.endTime,
      editedStartTime: false,
      editedEndTime: false,
      isSaveDisabled: false,
      currentTime: now().tz('Europe/Copenhagen').format('HH:mm'),
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isEditingTime() {
      return this.editedStartTime || this.editedEndTime;
    },
    showCancelButton() {
      if (this.needToAddSleepTime) {
        return this.editedStartTime;
      }
      return this.isEditingTime;
    },
    needToAddSleepTime() {
      return this.sleepTime.id && !this.sleepTime.endTime;
    },
    stylingVerticalLine() {
      if ((this.editedEndTime || this.needToAddSleepTime) && this.editedStartTime) {
        return 'updated';
      }
      if (this.editedEndTime || this.editedStartTime || this.needToAddSleepTime) {
        return 'hide';
      }
      return '';
    },
  },
  created() {
    if (!this.sleepTime.id) {
      this.editedStartTime = true;
      this.editedEndTime = true;
    }

    if (this.needToAddSleepTime) {
      this.endTime = this.currentTime;
    }
  },
  methods: {
    saveSleepTime() {
      this.isSaveDisabled = true;
      this.$emit('saveSleepTime', {
        id: this.sleepTime.id,
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },
    cancelAdjustingSleepTime() {
      if (!this.sleepTime.id) {
        this.$emit('cancelAddingSleepTime');
      } else {
        this.resetTimeSelectors();
      }
    },
    emitRemoveSleepInterval() {
      this.$emit('removeSleepInterval');
      this.$refs.removeSleepTimeWarning.hide();
    },
    emitTimePickerChange() {
      this.isSaveDisabled = false;
      this.$emit('onTimePickerChange');
    },
    onStartTimeChanged() {
      this.emitTimePickerChange();
      this.editedStartTime = true;
      if (this.needToAddSleepTime) {
        this.endTime = '';
      }
    },
    onEndTimeChanged() {
      this.emitTimePickerChange();
      if (this.endTime) {
        this.editedEndTime = true;
      }
    },
    resetTimeSelectors() {
      this.startTime = this.sleepTime.startTime;
      if (this.sleepTime.endTime) {
        this.endTime = this.sleepTime.endTime;
      } else {
        this.endTime = this.currentTime;
      }
      this.editedStartTime = false;
      this.editedEndTime = false;
      this.emitTimePickerChange();
    },
  },
};
