import { presenceOpenHours } from '../enums/presenceOpenHours';
import moment from 'moment-timezone';

class ComeGoUtil {
  defaultStart() {
    return '05:00';
  }

  defaultEnd() {
    return '23:59';
  }

  isSpecificOpeningHoursDay(date, closedDays) {
    return closedDays.some(day => day.date === date && day.type === presenceOpenHours.SPECIFIC_OPEN_HOURS);
  }

  getTimepickerStartFromOpeningHours(date, closedDays, openHours) {
    let timepickerStart = comeGoUtil.defaultStart();

    if (this.isSpecificOpeningHoursDay(date, closedDays)) {
      const openingHours = closedDays.find(day => day.date === date);
      if (openingHours.openTime) {
        timepickerStart = openingHours.openTime;
      }
    } else if (openHours[0].openingHours.length > 0) {
      const dayOfWeek = moment(date).day();
      const weekDay = openHours[0].openingHours.find(day => dayOfWeek === day.dayOfWeek);
      if (weekDay.openTime) {
        timepickerStart = weekDay.openTime;
      }
    }
    return timepickerStart;
  }

  getTimepickerEndFromOpeningHours(date, closedDays, openHours) {
    let timepickerEnd = this.defaultEnd();

    if (this.isSpecificOpeningHoursDay(date, closedDays)) {
      const openingHours = closedDays.find(day => day.date === date);
      if (openingHours.closeTime) {
        timepickerEnd = openingHours.closeTime;
      }
    } else if (openHours[0].openingHours.length > 0) {
      const dayOfWeek = moment(date).day();
      const weekDay = openHours[0].openingHours.find(day => dayOfWeek === day.dayOfWeek);
      if (weekDay.closeTime) {
        timepickerEnd = weekDay.closeTime;
      }
    }
    return timepickerEnd;
  }

  hasSpecifiedEntryTimeForVacationDay(vacationRequestDays) {
    return vacationRequestDays.some(vacationRequestDay => vacationRequestDay.entryTime !== '');
  }

  hasSpecifiedExitTimeForVacationDay(vacationRequestDays) {
    return vacationRequestDays.some(vacationRequestDay => vacationRequestDay.exitTime !== '');
  }

  updateEntryTimeForVacationRequestDays(vacationRequestDays, entryTime) {
    vacationRequestDays.forEach(vacationRequestDay => {
      if (vacationRequestDay.isDisabled || !vacationRequestDay.useTimes) {
        return;
      }
      vacationRequestDay.setEntryTime(entryTime);
    });
  }

  updateExitTimeForVacationRequestDays(vacationRequestDays, exitTime) {
    vacationRequestDays.forEach(vacationRequestDay => {
      if (vacationRequestDay.isDisabled || !vacationRequestDay.useTimes) {
        return;
      }
      vacationRequestDay.setExitTime(exitTime);
    });
  }

  getAlignedEntryTimeFromVacationRequestDays(vacationRequestDays) {
    const filteredVacationRequestDays = vacationRequestDays.filter(
      ({ isDisabled, useTimes }) => !isDisabled && useTimes
    );
    const daysLength = filteredVacationRequestDays.length;
    const firstVacationRequestDay = filteredVacationRequestDays[0];
    let entryTime = firstVacationRequestDay?.entryTime || '';
    for (let i = 1; i < daysLength; i++) {
      const vacationRequestDay = filteredVacationRequestDays[i];
      if (vacationRequestDay.entryTime !== entryTime) {
        entryTime = '';
        break;
      }
    }
    return entryTime;
  }

  getAlignedExitTimeFromVacationRequestDays(vacationRequestDays) {
    const filteredVacationRequestDays = vacationRequestDays.filter(
      ({ isDisabled, useTimes }) => !isDisabled && useTimes
    );
    const daysLength = filteredVacationRequestDays.length;
    const firstVacationRequestDay = filteredVacationRequestDays[0];
    let exitTime = firstVacationRequestDay?.exitTime || '';
    for (let i = 1; i < daysLength; i++) {
      const vacationRequestDay = filteredVacationRequestDays[i];
      if (vacationRequestDay.exitTime !== exitTime) {
        exitTime = '';
        break;
      }
    }
    return exitTime;
  }

  canUseTimesForVacationRequestDays(vacationRequestDays) {
    return vacationRequestDays.some(({ isDisabled, useTimes }) => !isDisabled && useTimes);
  }

  getLowestStartTimeFromVacationRequestDays(vacationRequestDays) {
    const filteredVacationRequestDays = vacationRequestDays.filter(
      ({ isDisabled, useTimes }) => !isDisabled && useTimes
    );
    const daysLength = filteredVacationRequestDays.length;
    const firstVacationRequestDay = filteredVacationRequestDays[0];
    let startTime = firstVacationRequestDay?.getStartTime() || '';
    for (let i = 1; i < daysLength; i++) {
      const vacationRequestDay = filteredVacationRequestDays[i];
      const vacationRequestDayStartTime = vacationRequestDay.getStartTime();
      if (moment(vacationRequestDayStartTime, 'HH:mm').isBefore(moment(startTime, 'HH:mm'))) {
        startTime = vacationRequestDayStartTime;
        break;
      }
    }
    return startTime;
  }

  getHighestEndTimeFromVacationRequestDays(vacationRequestDays) {
    const filteredVacationRequestDays = vacationRequestDays.filter(
      ({ isDisabled, useTimes }) => !isDisabled && useTimes
    );
    const daysLength = filteredVacationRequestDays.length;
    const firstVacationRequestDay = filteredVacationRequestDays[0];
    let endTime = firstVacationRequestDay?.getEndTime() || '';
    for (let i = 1; i < daysLength; i++) {
      const vacationRequestDay = filteredVacationRequestDays[i];
      const vacationRequestDayEndTime = vacationRequestDay.getEndTime();
      if (moment(vacationRequestDayEndTime, 'HH:mm').isAfter(moment(endTime, 'HH:mm'))) {
        endTime = vacationRequestDayEndTime;
        break;
      }
    }
    return endTime;
  }

  getAreAllVacationRequestDaysDisabled(vacationRequestDays) {
    return vacationRequestDays.every(vacationRequestDay => vacationRequestDay.isDisabled);
  }

  getIsComingValueForAllVacationRequestDays(vacationRequestDays) {
    const filteredVacationRequestDays = vacationRequestDays.filter(({ isDisabled }) => !isDisabled);
    const daysLength = filteredVacationRequestDays.length;
    const firstVacationRequestDay = filteredVacationRequestDays[0];
    let isComing = firstVacationRequestDay?.isComing;

    for (let i = 1; i < daysLength; i++) {
      const vacationRequestDay = filteredVacationRequestDays[i];

      if (isComing !== vacationRequestDay.isComing) {
        isComing = null;
        break;
      }
    }

    return isComing;
  }

  updateIsComingValueForVacationRequestDays(vacationRequestDays, isComingValue) {
    vacationRequestDays.forEach(vacationRequestDay => {
      if (vacationRequestDay.isDisabled) {
        return;
      }
      vacationRequestDay.setIsComing(isComingValue);
    });
  }
}

export const comeGoUtil = new ComeGoUtil();
