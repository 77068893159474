export const PRESENCE_NAVBAR_ALL = 'Alle';
export const PRESENCE_SAVE = 'GEM';
export const PRESENCE_GOING_HOME_WITH = 'Tager med hjem:';
export const PRESENCE_SAVE_VACATION_TOASTR = 'Ferie/fri er oprettet og kan ses i kalenderen';
export const TOO_MANY_CHILDREN_SELECTED = 'Du har valgt for mange børn at redigere';
export const PRESENCE_WARNING_REMOVE_VACATION =
  'Du er ved at fjerne registreret ferie. Er du sikker på, at du vil fortsætte?';
export const PRESENCE_REGISTER_VACATION_WARNING = 'Du skal angive en gyldig start og slut dato';
export const PRESENCE_SLEEP_TIME_WARNING = 'Sluttid kan ikke være før start';
export const PRESENCE_SLEEP_INVALID_TIME_WARNING = 'Du har ikke valgt en gyldig tid';
export const PRESENCE_START_TIME_IN_FUTURE_WARNING = 'Starttid kan ikke være i fremtiden';
export const PRESENCE_PICKUP_TIME_WARNING = 'Tjek ud kan ikke være før tjek ind.';
export const PRESENCE_SELF_DECIDER_TIME_WARNING = 'Til tidspunkt kan ikke være før fra tidspunkt.';
export const PRESENCE_NO_EXIT_WITH_WARNING = 'Du skal vælge en person';
export const PRESENCE_NO_PICKUP_TYPE = 'Du skal vælge en henteaktivitet';
export const PRESENCE_SELF_DECIDER_START_TIME = 'Fra kl.:';
export const PRESENCE_SELF_DECIDER_END_TIME = 'Til kl.:';
export const PRESENCE_SELF_CHECKIN_TIME = 'Kommer kl.:';
export const PRESENCE_SELF_CHECKOUT_TIME = 'Går kl.:';
export const PRESENCE_TOO_SHORT_NAME_WARNING = 'Du skal mindst skrive 2 tegn';
export const PRESENCE_HEADER = 'Komme/gå';
export const PRESENCE_ALERT_OUTSIDE_OPEN_HOURS = 'Du har angivet en tid, der ligger udenfor åbningstiderne';
export const PRESENCE_PICK_UP_BY = 'Hentes af';
export const PRESENCE_SEND_HOME = 'Send hjem';
export const PRESENCE_GO_HOME_WITH = 'Gå hjem med';
export const PRESENCE_SELF_DECIDER = 'Selvbestemmer';
export const PRESENCE_SPARE_TIME = 'Fritidsaktivitet';
export const PRESENCE_GO_HOME_WITH_MODULE = 'Gå hjem med ';
export const PRESENCE_DEPARTMENT = 'Afdelinger:';
export const PRESENCE_CHILDREN_COUNT = '{children} ud af {allChildren} børn er til stede';
export const PRESENCE_CHILD_COUNT = '{children} ud af {allChildren} barn er til stede';
export const PRESENCE_SLEEP_START_TIME = 'Start:';
export const PRESENCE_SLEEP_END_TIME = 'Slut:';
export const PRESENCE_SLEEP_NO_END_TIME = 'Ingen';
export const PRESENCE_PHYSICAL_PLACEMENT = 'Placeringer';
export const PRESENCE_SLEEP = 'Søvn';
export const PRESENCE_SLEEP_TOTAL_TIME = '(i alt: {sleepIntervalSum} minutter)';
export const PRESENCE_ADD_SLEEP_TIME = 'Tilføj sovetid';
export const PRESENCE_DELETE_SLEEP_TIME = 'Slet sovetid';
export const PRESENCE_DELETE_SLEEP_TIME_WARNING = 'Du er ved at slette denne sovetid.';
export const PRESENCE_DELETE_SLEEP_TIME_CONFIRMATION = 'Vil du fortsætte?';
export const PRESENCE_CHECK_IN = 'Tjek ind';
export const PRESENCE_CHECK_OUT = 'Tjek ud';
export const PRESENCE_OUT_DOOR_ACTIVITIES = 'Ude af huset';
export const PRESENCE_REPORT_ABSENCE = 'Fravær';
export const PRESENCE_SICK = 'Syg';
export const PRESENCE_FIELDTRIP = 'På tur';
export const PRESENCE_SPARE_TIME_ACTIVITY = 'Fritidsaktivitet';
export const PRESENCE_SPARE_TIME_ACTIVITY_NOTE = 'Note til fritidsaktivitet';
export const PRESENCE_NOT_PRESENT = 'Ikke til stede';
export const PRESENCE_PRESENT = 'Kommet';
export const PRESENCE_SLEEPING = 'Sover';
export const PRESENCE_CHECKED_OUT = 'Gået';
export const PRESENCE_VACATION = 'Ferie/fri';
export const PRESENCE_REPORTED_ABSENT = 'Ferie/fri';
export const PRESENCE_SPECIFIC_HOURS_INFO = 'Bemærk: Ændrede åbningstider:';

export const ARIA_LABEL_CHOOSE_DEPARTMENT = 'Vælg afdeling';
export const ARIA_LABEL_CHOOSE_PICKUP_TYPE = 'Vælg hentetype';
export const ARIA_LABEL_CHOOSE_EXIT_WITH = 'Vælg exit med';
export const ARIA_LABEL_CHOOSE_SLEEP_START_TIME = 'Vælg en søvn starttid';
export const ARIA_LABEL_CHOOSE_SLEEP_END_TIME = 'Vælg en søvn sluttid';
export const ARIA_LABEL_CHOOSE_SELF_DECIDER_START_TIME = 'Vælg en selvbestemmer starttid';
export const ARIA_LABEL_CHOOSE_SELF_DECIDER_END_TIME = 'Vælg en selvbestemmer sluttid';
export const ARIA_LABEL_CHOOSE_CHECK_OUT_TIME = 'Vælg tjek ud';
export const ARIA_LABEL_CHOOSE_CHECK_IN_TIME = 'Vælg tjek ind';
export const ARIA_LABEL_CHOOSE_SPARE_ACTIVITY = 'Vælg Til fritidsaktivitet';
export const ARIA_LABEL_CHOOSE_ABSENCE = 'Vælg fravær';
export const ARIA_LABEL_CHOOSE_FIELD_TRIP = 'Vælg på tur';
export const PRESENCE_PLACEHOLDER = 'Vælg';
export const PRESENCE_BLANK_PLACEHOLDER = '--:--';
export const PRESENCE_WARNING_NO_EXIT_TIME = 'Du mangler at vælge en tid';
export const PRESENCE_WARNING_INVALID_CONFIGURATION = 'Du kan ikke gemme denne type uden at angive en hente tid';
export const PRESENCE_COMMENT = 'Bemærkninger';
