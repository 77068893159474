<template>
  <div>
    <span v-if="isHeaderVisible">
      <span class="small-text">{{ 'PRESENCE_OUT_DOOR_ACTIVITIES' | fromTextKey }}</span>
      <div class="border-bottom" />
    </span>
    <div class="presence-icons">
      <div v-if="isFieldTripVisible" class="presence-icon-container">
        <IconContainer
          v-button
          class="icon-circle"
          :class="{ active: status == presenceStates.FIELDTRIP }"
          :aria-label="'ARIA_LABEL_CHOOSE_FIELD_TRIP' | fromTextKey"
          @click="
            updateFieldTrip(status != presenceStates.FIELDTRIP ? presenceStates.FIELDTRIP : presenceStates.PRESENT)
          "
        >
          <Icon :name="iconClassEnum.BACKPACK" />
        </IconContainer>
        <div class="icons-description">
          {{ 'PRESENCE_FIELDTRIP' | fromTextKey }}
        </div>
      </div>
      <div v-if="isSpareTimeVisible" class="presence-icon-container">
        <IconContainer
          v-button
          class="icon-circle"
          :class="{
            active: status == presenceStates.SPARE_TIME_ACTIVITY,
            disabled: !configuration[presenceModules.SPARE_TIME_ACTIVITY].editable,
          }"
          :aria-label="'ARIA_LABEL_CHOOSE_SPARE_ACTIVITY' | fromTextKey"
          @click="
            updateSpareActivity(
              status != presenceStates.SPARE_TIME_ACTIVITY ? presenceStates.SPARE_TIME_ACTIVITY : presenceStates.PRESENT
            )
          "
        >
          <Icon :name="iconClassEnum.WALKING_PERSON" />
        </IconContainer>
        <div class="icons-description">
          {{ 'PRESENCE_SPARE_TIME_ACTIVITY' | fromTextKey }}
        </div>
      </div>
      <div v-if="isCommentVisible" class="activity-comment">
        <strong>{{ 'PRESENCE_SPARE_TIME_ACTIVITY_NOTE' | fromTextKey }}</strong
        ><br />
        {{ comment }}
      </div>
    </div>
  </div>
</template>
<script>
import { presenceStates } from '../../enums/presenceStates';
import { presenceModules } from '../../enums/presenceModules';
import IconContainer from '../IconContainer.vue';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import button from '../../directives/button';

export default {
  components: { Icon, IconContainer },
  directives: {
    button,
  },
  props: {
    status: { type: Number },
    comment: { type: String, default: null },
    configuration: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      showVacationForm: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isFieldTripVisible() {
      return (
        this.configuration[presenceModules.FIELD_TRIP] != null &&
        this.configuration[presenceModules.FIELD_TRIP].readable
      );
    },
    isSpareTimeVisible() {
      return (
        this.configuration[presenceModules.SPARE_TIME_ACTIVITY] != null &&
        this.configuration[presenceModules.SPARE_TIME_ACTIVITY].readable
      );
    },
    isCommentVisible() {
      return this.comment != null && this.comment != '';
    },
    isHeaderVisible() {
      return this.isCommentVisible || this.isSpareTimeVisible || this.isFieldTripVisible;
    },
  },
  methods: {
    updateFieldTrip(status) {
      if (this.configuration[presenceModules.FIELD_TRIP].editable) {
        this.$emit('updateChildStatus', status);
      }
    },
    updateSpareActivity(status) {
      if (this.configuration[presenceModules.SPARE_TIME_ACTIVITY].editable) {
        this.$emit('updateChildStatus', status);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/presence/presence-checkin-dashboard';
</style>
