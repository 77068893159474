<template>
  <div>
    <span class="small-text">{{ 'PRESENCE_PHYSICAL_PLACEMENT' | fromTextKey }}</span>
    <div class="border-bottom" />
    <div class="presence-icons">
      <div
        v-for="location in locations"
        :id="'location' + location.id"
        :key="location.id"
        class="presence-icon-container"
      >
        <IconContainer
          v-button
          class="icon-circle"
          :class="{
            disabled: !configuration[presenceModules.LOCATION].editable,
            active: activeLocationId == location.id,
          }"
          @click="updateChildLocation(location)"
          @keydown.enter="updateChildLocation(location)"
        >
          <Icon :name="location.symbol" />
        </IconContainer>
        <div class="icons-description">
          {{ location.name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { presenceModules } from '../../enums/presenceModules';
import IconContainer from '../IconContainer.vue';
import Icon from '../Icon.vue';
import button from '../../directives/button';

export default {
  components: { Icon, IconContainer },
  directives: {
    button,
  },
  props: {
    activeLocationId: { type: Number, default: null },
    locations: { type: Array, default: () => [] },
    configuration: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      presenceModules: presenceModules,
    };
  },
  methods: {
    updateChildLocation(location) {
      if (this.configuration[presenceModules.LOCATION].editable) {
        this.$emit('updateChildLocation', location);
      }
    },
    updateChildStatus(status) {
      this.$emit('updateChildStatus', status);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/presence/presence-checkin-dashboard';
</style>
