/**
 * @typedef listener
 * @type {Function}
 * @param {MediaQueryListEvent} event
 */
export class MatchMediaService {
  /**
   * @type {Array<listener>}
   */
  #listeners = [];

  /**
   * @param {String} query
   */
  constructor(query) {
    if (window.matchMedia != null) {
      const mediaQueryList = window.matchMedia(query);
      if (typeof mediaQueryList.addEventListener === 'function') {
        mediaQueryList.addEventListener('change', event => {
          this.#listeners.forEach(listener => listener(event));
        });
      }
    }
  }

  /**
   * @param {listener} listener
   */
  addEventListener(listener) {
    this.#listeners.push(listener);
  }

  /**
   * @param {listener} listener
   */
  removeEventListener(listener) {
    const index = this.#listeners.findIndex(mediaListener => mediaListener === listener);
    if (index > -1) {
      this.#listeners.splice(index, 1);
    }
  }
}
