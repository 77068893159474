var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"child-header mb-4"},[(_vm.children.length == 1)?[(_vm.children.length == 1)?_c('div',{staticClass:"user-photo"},[_c('user-icon',{attrs:{"id":_vm.children[0].id,"show-inline":true,"name":_vm.children[0].name,"short-name":_vm.children[0].shortName,"picture-object":_vm.children[0].profilePicture}})],1):_vm._e(),_vm._v(" "),(_vm.children.length == 1)?_c('div',{staticClass:"child-check-in"},[_c('div',{staticClass:"child-name"},[_vm._v("\n          "+_vm._s(_vm._f("displayProfileNameWithMetadata")(_vm.children[0]))),_c('br'),_vm._v(" "),_c('b-btn',{staticClass:"btn-update-status",attrs:{"variant":_vm.isEmployeeDashboard ? 'primary' : 'white'},on:{"click":function($event){return _vm.updateChildStatus(
                _vm.status == _vm.presenceStates.NOT_PRESENT ||
                  _vm.status == _vm.presenceStates.CHECKED_OUT ||
                  _vm.status == _vm.presenceStates.SICK ||
                  _vm.status == _vm.presenceStates.REPORTED_ABSENT
                  ? _vm.presenceStates.PRESENT
                  : _vm.presenceStates.CHECKED_OUT
              )}}},[_vm._v("\n            "+_vm._s(_vm._f("fromTextKey")(_vm.status == _vm.presenceStates.NOT_PRESENT ||
              _vm.status == _vm.presenceStates.CHECKED_OUT ||
              _vm.status == _vm.presenceStates.SICK ||
              _vm.status == _vm.presenceStates.REPORTED_ABSENT
                ? 'PRESENCE_CHECK_IN'
                : 'PRESENCE_CHECK_OUT'))+"\n          ")])],1)]):_vm._e()]:_vm._l((_vm.children),function(child){return _c('span',{key:child.id,staticClass:"child"},[_vm._v("\n      "+_vm._s(_vm._f("displayProfileNameWithMetadata")(child))+"\n      "),_c('b-btn',{staticClass:"remove-child",attrs:{"variant":"link","aria-label":_vm._f("fromTextKey")('ARIA_LABEL_PRESENCE_DASHBOARD_REMOVE_CHILD')},on:{"click":function($event){return _vm.removeChild(child)}}},[_c('i',{staticClass:"icon-Aula_close"})])],1)})],2),_vm._v(" "),(_vm.children.length > 1)?_c('div',{staticClass:"update-status"},[_c('b-btn',{staticClass:"btn-update-status",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateChildStatus(_vm.presenceStates.PRESENT, true)}}},[_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('PRESENCE_STATUS_CHECK_IN'))+"\n    ")]),_vm._v(" "),_c('b-btn',{staticClass:"btn-update-status",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.updateChildStatus(_vm.presenceStates.CHECKED_OUT, true)}}},[_vm._v("\n      "+_vm._s(_vm._f("fromTextKey")('PRESENCE_STATUS_CHECK_OUT'))+"\n    ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }