<template>
  <div>
    <span v-if="isHeaderVisible">
      <span class="small-text">{{ 'PRESENCE_REPORT_ABSENCE' | fromTextKey }}</span>
      <div class="border-bottom" />
    </span>
    <div class="presence-icons">
      <div v-if="isSickSettingVisible" class="presence-icon-container">
        <IconContainer
          v-button
          class="icon-circle"
          :class="{
            disabled: !configuration[presenceModules.REPORT_SICK].editable,
            active: status == presenceStates.SICK,
          }"
          @click="updateChildStatus(status != presenceStates.SICK ? presenceStates.SICK : presenceStates.PRESENT)"
        >
          <Icon :name="iconClassEnum.SYG_IKON" />
        </IconContainer>
        <div class="icons-description">
          {{ 'PRESENCE_SICK' | fromTextKey }}
        </div>
      </div>
      <div v-if="isVacationSettingVisible" class="presence-icon-container">
        <IconContainer
          v-button
          class="icon-circle"
          :class="{
            disabled: !configuration[presenceModules.VACATION].editable,
            active: showVacationForm || status == presenceStates.REPORTED_ABSENT,
          }"
          @click="showVacation"
        >
          <Icon :name="iconClassEnum.FERIE_IKON" />
        </IconContainer>
        <div class="icons-description">
          {{ 'PRESENCE_VACATION' | fromTextKey }}
        </div>
      </div>
      <vacation-form
        v-if="isVacationFormVisible"
        :child-ids="childIds"
        :is-disabled-start-date="false"
        @updateChildStatus="updateChildStatus"
        @saveVacation="saveVacation"
        @cancelVacationForm="showVacationForm = !showVacationForm"
      />
    </div>
  </div>
</template>
<script>
import { presenceStates } from '../../enums/presenceStates';
import { presenceModules } from '../../enums/presenceModules';
import VacationForm from './VacationForm';
import IconContainer from '../IconContainer.vue';
import Icon from '../Icon.vue';
import { iconClassEnum } from '../../enums/iconClassEnum';
import button from '../../directives/button';

export default {
  components: {
    Icon,
    IconContainer,
    VacationForm,
  },
  directives: {
    button,
  },
  props: {
    childIds: { type: Array },
    status: { type: Number },
    configuration: { type: Object, default: () => {} },
  },
  data: function () {
    return {
      presenceStates: presenceStates,
      presenceModules: presenceModules,
      showVacationForm: false,
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isSickSettingVisible() {
      return (
        this.configuration[presenceModules.REPORT_SICK] != null &&
        this.configuration[presenceModules.REPORT_SICK].readable
      );
    },
    isVacationSettingVisible() {
      return (
        this.configuration[presenceModules.VACATION] != null && this.configuration[presenceModules.VACATION].readable
      );
    },
    isVacationFormVisible() {
      return this.showVacationForm && this.configuration[presenceModules.VACATION].editable;
    },
    isHeaderVisible() {
      return this.isVacationFormVisible || this.isVacationSettingVisible || this.isSickSettingVisible;
    },
  },
  methods: {
    showVacation() {
      if (this.configuration[presenceModules.VACATION].editable) {
        this.showVacationForm = !this.showVacationForm;
      }
    },
    updateChildStatus(status) {
      if (this.configuration[presenceModules.REPORT_SICK].editable) {
        this.$emit('updateChildStatus', status, true);
      }
    },
    saveVacation() {
      this.$emit('saveVacation');
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/presence/presence-checkin-dashboard';
</style>
