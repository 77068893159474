






































import { presenceModules } from '../../enums/presenceModules.js';
import moment from 'moment-timezone';
import SleepTimePicker from './SleepTimePicker.vue';
import cloneDeep from 'lodash/cloneDeep';
import { PropType } from 'vue';
import { SleepIntervalModel } from '../../models/sleepIntervalModel';
import { iconClassEnum } from '../../enums/iconClassEnum';
import Icon from '../Icon.vue';
import { now } from '../../utils/dateUtil.js';
import AulaButton from '../AulaButton.vue';
import uniqueId from 'lodash/uniqueId';

export default {
  components: { AulaButton, Icon, SleepTimePicker },
  props: {
    sleepIntervals: { type: Array as PropType<SleepIntervalModel[]>, default: () => [] },
    configuration: { type: Object },
    canEdit: { type: Boolean, default: true },
    isEmployeeDashboard: { type: Boolean, default: false },
  },
  emits: ['saveSleepInterval', 'editSleepInterval', 'removeSleepInterval'],
  data: function () {
    return {
      presenceModules: presenceModules,
      showAlertStartAfterEndTime: false,
      showAlertStartTimeInFuture: false,
      showAlertInvalidTime: false,
      sleepTimeList: [],
    };
  },
  computed: {
    iconClassEnum() {
      return iconClassEnum;
    },
    isEditable() {
      return this.configuration[presenceModules.SLEEP] != null && this.configuration[presenceModules.SLEEP].editable;
    },
    sleepIntervalSum() {
      let sum = 0;
      for (const interval of this.sleepIntervals) {
        if (
          interval.startTime != null &&
          interval.startTime != '' &&
          interval.endTime != null &&
          interval.endTime != ''
        ) {
          const startTime = moment().hours(interval.startTime.split(':')[0]).minutes(interval.startTime.split(':')[1]);
          const endTime = moment().hours(interval.endTime.split(':')[0]).minutes(interval.endTime.split(':')[1]);
          sum += endTime.diff(startTime, 'minutes');
        }
      }
      return sum;
    },
    isAddingNewSleepTime() {
      return this.sleepTimeList.some(sleepTime => sleepTime.id === '');
    },
    hasOpenSleepTime() {
      return this.sleepTimeList.some(sleepTime => sleepTime.endTime === '');
    },
    headerText() {
      if (!this.isEmployeeDashboard) {
        return `${this.$options.filters.fromTextKey('PRESENCE_SLEEP')}
        ${this.$options.filters.fromTextKey('PRESENCE_SLEEP_TOTAL_TIME', {
          sleepIntervalSum: this.sleepIntervalSum,
        })}`;
      }
      return this.$options.filters.fromTextKey('PRESENCE_SLEEP');
    },
  },
  watch: {
    sleepIntervals: function () {
      this.updateSleepTimeList();
    },
  },
  created() {
    this.updateSleepTimeList();
  },
  methods: {
    updateSleepTimeList() {
      this.sleepTimeList = cloneDeep(this.sleepIntervals);
      this.sleepTimeList.map(item => (item.key = uniqueId()));
    },
    hideAlerts() {
      this.showAlertStartAfterEndTime = false;
      this.showAlertStartTimeInFuture = false;
      this.showAlertInvalidTime = false;
    },
    saveSleepTime(sleepTime) {
      if (sleepTime.id === '') {
        this.saveSleepInterval(sleepTime);
      } else {
        this.editSleepInterval(sleepTime);
      }
    },
    cancelAddingSleepTime(index) {
      this.sleepTimeList.splice(index, 1);
      this.hideAlerts();
    },
    saveSleepInterval(sleepTime) {
      const denmarkTimeNow = moment.tz('Europe/Copenhagen');
      if (!sleepTime.startTime && sleepTime.endTime) {
        if (moment(sleepTime.endTime, 'HH:mm').tz('Europe/Copenhagen').isBefore(denmarkTimeNow)) {
          return;
        }
      }
      if (sleepTime.startTime) {
        if (moment(sleepTime.startTime, 'HH:mm').tz('Europe/Copenhagen').isAfter(denmarkTimeNow)) {
          this.showAlertStartTimeInFuture = true;
          return;
        }
        if (!moment(sleepTime.startTime, 'HH:mm', true).isValid()) {
          this.showAlertInvalidTime = true;
          return;
        }
      }
      if (!sleepTime.startTime) {
        sleepTime.startTime = denmarkTimeNow.format('HH:mm');
      }
      const sleepInterval = { start: sleepTime.startTime, end: '' };
      if (sleepTime.endTime) {
        if (!moment(sleepTime.endTime, 'HH:mm', true).isValid()) {
          this.showAlertInvalidTime = true;
          return;
        }
        if (
          moment(sleepTime.endTime, 'HH:mm')
            .tz('Europe/Copenhagen')
            .isBefore(moment(sleepTime.startTime, 'HH:mm').tz('Europe/Copenhagen'))
        ) {
          this.showAlertStartAfterEndTime = true;
          return;
        }
        sleepInterval.end = sleepTime.endTime;
      }

      this.$emit('saveSleepInterval', sleepInterval);
    },
    newSleepInterval() {
      this.hideAlerts();
      this.sleepTimeList.push({
        key: uniqueId(),
        id: '',
        startTime: now().tz('Europe/Copenhagen').format('HH:mm'),
        endTime: '',
      });
    },
    removeSleepInterval(sleepTime) {
      this.$emit('removeSleepInterval', sleepTime);
      this.hideAlerts();
    },
    editSleepInterval(sleepInterval) {
      const denmarkTimeNow = moment.tz('Europe/Copenhagen');
      if (sleepInterval.startTime) {
        if (moment(sleepInterval.startTime, 'HH:mm').tz('Europe/Copenhagen').isAfter(denmarkTimeNow)) {
          this.showAlertStartTimeInFuture = true;
          return;
        } else {
          this.showAlertStartTimeInFuture = false;
        }
        if (sleepInterval.endTime) {
          if (!moment(sleepInterval.endTime, 'HH:mm', true).isValid()) {
            this.showAlertInvalidTime = true;
            return;
          } else {
            this.showAlertInvalidTime = false;
          }
          if (moment(sleepInterval.startTime, 'HH:mm').isAfter(moment(sleepInterval.endTime, 'HH:mm'))) {
            this.showAlertStartAfterEndTime = true;
            return;
          } else {
            this.showAlertStartAfterEndTime = false;
          }
        }
      }
      this.$emit('editSleepInterval', sleepInterval);
    },
  },
};
