<template>
  <div>
    <div class="child-header mb-4">
      <template v-if="children.length == 1">
        <div v-if="children.length == 1" class="user-photo">
          <user-icon
            :id="children[0].id"
            :show-inline="true"
            :name="children[0].name"
            :short-name="children[0].shortName"
            :picture-object="children[0].profilePicture"
          />
        </div>
        <div v-if="children.length == 1" class="child-check-in">
          <div class="child-name">
            {{ children[0] | displayProfileNameWithMetadata }}<br />
            <b-btn
              :variant="isEmployeeDashboard ? 'primary' : 'white'"
              class="btn-update-status"
              @click="
                updateChildStatus(
                  status == presenceStates.NOT_PRESENT ||
                    status == presenceStates.CHECKED_OUT ||
                    status == presenceStates.SICK ||
                    status == presenceStates.REPORTED_ABSENT
                    ? presenceStates.PRESENT
                    : presenceStates.CHECKED_OUT
                )
              "
            >
              {{
                status == presenceStates.NOT_PRESENT ||
                status == presenceStates.CHECKED_OUT ||
                status == presenceStates.SICK ||
                status == presenceStates.REPORTED_ABSENT
                  ? 'PRESENCE_CHECK_IN'
                  : 'PRESENCE_CHECK_OUT' | fromTextKey
              }}
            </b-btn>
          </div>
        </div>
      </template>
      <span v-for="child in children" v-else :key="child.id" class="child">
        {{ child | displayProfileNameWithMetadata }}
        <b-btn
          variant="link"
          class="remove-child"
          :aria-label="'ARIA_LABEL_PRESENCE_DASHBOARD_REMOVE_CHILD' | fromTextKey"
          @click="removeChild(child)"
        >
          <i class="icon-Aula_close" />
        </b-btn>
      </span>
    </div>
    <div v-if="children.length > 1" class="update-status">
      <b-btn variant="primary" class="btn-update-status" @click="updateChildStatus(presenceStates.PRESENT, true)">
        {{ 'PRESENCE_STATUS_CHECK_IN' | fromTextKey }}
      </b-btn>
      <b-btn variant="primary" class="btn-update-status" @click="updateChildStatus(presenceStates.CHECKED_OUT, true)">
        {{ 'PRESENCE_STATUS_CHECK_OUT' | fromTextKey }}
      </b-btn>
    </div>
  </div>
</template>
<script>
import { presenceStates } from '../../enums/presenceStates';

export default {
  props: {
    children: { type: Array },
    status: { type: Number },
    isEmployeeDashboard: { type: Boolean, default: false },
  },
  data: function () {
    return {
      presenceStates: presenceStates,
    };
  },
  methods: {
    removeChild(child) {
      this.$emit('removeChild', child);
    },
    updateChildStatus(status) {
      this.$emit('updateChildStatus', status);
    },
  },
};
</script>
<style lang="scss" scoped>
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/presence/presence-checkin-dashboard';
</style>
